






































import EnumerationUtils, { Enumeration } from '@/utils/EnumerationUtils';
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: Object as () => Partial<Enumeration>,
    },
    mode: {
      type: String as () => 'create' | 'edit',
      required: true,
    },
    'error-messages': {
      type: Object as () => { [key: string]: string[] },
      default: () => ({}),
    },
  },

  computed: {
    internal: {
      get(): Partial<Enumeration> {
        return this.value || EnumerationUtils.enumeration.generateDefault();
      },
      set(val: Partial<Enumeration>) {
        this.$emit('input', val);
      },
    },
  },
});
