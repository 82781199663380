




















import EnumerationForm from '@/components/enumeration/EnumerationForm.vue';
import EnumerationUtils, { Enumeration } from '@/utils/EnumerationUtils';
import Vue from 'vue';

export default Vue.extend({
  components: { EnumerationForm },

  data(): {
    state: {
      valid: boolean;
      submit: boolean;
      errors?: any[];
    };
    item?: Partial<Enumeration>;
  } {
    return {
      state: {
        valid: false,
        submit: false,
        errors: undefined,
      },
      item: undefined,
    };
  },

  async mounted() {
    this.item = EnumerationUtils.enumeration.generateDefault();
  },

  methods: {
    async handleSubmit() {
      if (this.item) {
        try {
          this.state.errors = undefined;
          this.state.submit = true;
          (this.$refs.form as any).validate();
          this.item = await EnumerationUtils.api.create(this.item);
          (this.$refs.form as any).resetValidation();
          this.$toast.push({
            text: this.$t('success.create', [this.$tc('community.label')]),
            type: 'success',
          });
          this.$router.replace({ name: 'enumeration-list' });
        } catch (error: any) {
          this.$toast.push({
            text: this.$t('error.create', [this.$tc('enumeration.label')]),
            type: 'error',
          });
          this.state.errors = error; // TODO: handle errors
        } finally {
          this.state.submit = false;
        }
      }
    },
  },
});
